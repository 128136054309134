import { animate, query, style, transition, trigger } from '@angular/animations';
import { EChartsOption, graphic } from 'echarts';
import { ZevaPageDictionary, ZevaPageSelector, ZevaSubsystemsInterface } from './core.models';

/** Time format that will be expected as an accurate timestamp input for ZEVA backend */
export const ZEVA_TIMESTAMP_OUTPUT = 'YYYY-MM-DDTHH:mm:ssZ';

/** Time format for simple date display without time */
export const ZEVA_DATE_DISPLAY = 'YYYY-MM-DD';
/** Time format for simple date display with hours and minutes */
export const ZEVA_DATETIME_DISPLAY = 'YYYY-MM-DD HH:mm';
/** Time format for simple date display with hours, minutes and seconds */
export const ZEVA_TIMESTAMP_DISPLAY = 'YYYY-MM-DD HH:mm:ss';
/** Time format for short Month display with full Year and short month */
export const ZEVA_DATE_MONTH_SHORT = 'YYYY, MMM';

/**
 * Default format for any displayable numeric values. Use with Angular's common DecimalPipe.
 * @example
 * //* In HTML Template
 * {{ 9999999 | number: ZEVA_NUMBER_FORMAT }},
 *
 * //* In TS Component
 * constructor(private decimalPipe: DecimalPipe) {}
 *
 * member() {
 *   this.decimalPipe.transform(9999999, ZEVA_NUMBER_FORMAT);
 * }
 */
export const ZEVA_NUMBER_FORMAT = '0.0-2';

/**
 * Text placeholder for values that are either undefined, null or simply invalid
 * to be displayed normally
 */
export const ZEVA_DATA_PLACEHOLDER = '--';
export const NAString = '--';
export const NoNumericDataString = '--';

export type ZevaTimeDisplayFormat =
  | typeof ZEVA_DATE_DISPLAY
  | typeof ZEVA_DATETIME_DISPLAY
  | typeof ZEVA_TIMESTAMP_DISPLAY;

/* Information of all Zeva subsystems */
export const ZEVA_SUBSYSTEMS: ZevaSubsystemsInterface = {
  insights: {
    id: 1,
    name: 'Insights',
    slug: 'insights',
  },
  analytics: {
    id: 2,
    name: 'Analytics',
    slug: 'analytics',
  },
  monitoring: {
    id: 3,
    name: 'Monitoring',
    slug: 'monitoring',
  },
  admin: {
    id: 4,
    name: 'Admin',
    slug: 'admin',
  },
};

/* Information of all Zeva pages */
export const ZEVA_PAGES: ZevaPageDictionary = {
  // Insights
  [ZevaPageSelector.INSIGHTS]: {
    id: 1,
    name: 'Insights',
    slug: 'insights',
  },
  // Analytics
  [ZevaPageSelector.ANALYTICS_VEHICLE]: {
    id: 2,
    name: 'Analytics',
    slug: 'vehicle',
  },
  // Monitoring
  [ZevaPageSelector.MONITORING_LIVE]: {
    id: 3,
    name: 'Live',
    slug: 'live',
  },
  [ZevaPageSelector.MONITORING_REPLAY]: {
    id: 4,
    name: 'Replay',
    slug: 'replay',
  },
  [ZevaPageSelector.MONITORING_CONTROL]: {
    id: 5,
    name: 'Control',
    slug: 'control',
  },
  [ZevaPageSelector.MONITORING_EN]: {
    id: 6,
    name: 'Energy',
    slug: 'energy',
  },
  [ZevaPageSelector.MONITORING_GEOFENCES]: {
    id: 7,
    name: 'Geofences',
    slug: 'geofences',
  },
  // Admin
  [ZevaPageSelector.ADMIN_ACCOUNT]: {
    id: 8,
    name: 'Account',
    slug: 'account',
  },
  [ZevaPageSelector.ADMIN_PROFILE]: {
    id: 9,
    name: 'Profile',
    slug: 'profile',
  },
  [ZevaPageSelector.ADMIN_FLEET]: {
    id: 10,
    name: 'Fleet',
    slug: 'fleet',
  },
  [ZevaPageSelector.ADMIN_SUBSCRIPTION]: {
    id: 11,
    name: 'Subscription',
    slug: 'subscription',
  },
  [ZevaPageSelector.ADMIN_MANAGE_SUBSCRIPTION]: {
    id: 12,
    name: 'Manage Subscription',
    slug: 'manage-subscription',
  },
  [ZevaPageSelector.ADMIN_MANAGE_VEHICLES]: {
    id: 13,
    name: 'Manage Vehicles',
    slug: 'manage-vehicles',
  },
  [ZevaPageSelector.ADMIN_PAYMENT_DETAILS]: {
    id: 14,
    name: 'Payment Details',
    slug: 'payment-details',
  },
  [ZevaPageSelector.ADMIN_PAYMENT_HISTORY]: {
    id: 15,
    name: 'Payment History',
    slug: 'payment-history',
  },
  [ZevaPageSelector.ADMIN_UPDATE_SUBSCRIPTION]: {
    id: 16,
    name: 'Update Subscription',
    slug: 'update-subscription',
  },
  [ZevaPageSelector.FAQ]: {
    id: 17,
    name: 'FAQ',
    slug: 'faq',
  },
  [ZevaPageSelector.GET_STARTED]: {
    id: 18,
    name: 'Get Started',
    slug: 'get-started',
  },
};

export const ZEVA_PAGE_VIEW_PERMISSION: { [key in ZevaPageSelector]: string } = {
  [ZevaPageSelector.INSIGHTS]: 'app_insights',
  [ZevaPageSelector.ANALYTICS_VEHICLE]: 'app_analytics',
  [ZevaPageSelector.MONITORING_LIVE]: 'app_live',
  [ZevaPageSelector.MONITORING_REPLAY]: 'app_replay',
  [ZevaPageSelector.MONITORING_CONTROL]: 'app_control',
  [ZevaPageSelector.MONITORING_EN]: 'app_energy',
  [ZevaPageSelector.MONITORING_GEOFENCES]: 'app_geofences',
  [ZevaPageSelector.ADMIN_PROFILE]: '',
  [ZevaPageSelector.ADMIN_FLEET]: 'app_fleet',
  [ZevaPageSelector.ADMIN_ACCOUNT]: 'app_account',
  [ZevaPageSelector.ADMIN_SUBSCRIPTION]: 'app_subscription',
  [ZevaPageSelector.ADMIN_MANAGE_SUBSCRIPTION]: 'app_subscription_manage_subscription',
  [ZevaPageSelector.ADMIN_MANAGE_VEHICLES]: 'app_vehicles',
  [ZevaPageSelector.ADMIN_PAYMENT_DETAILS]: 'app_subscription_payment_details',
  [ZevaPageSelector.ADMIN_PAYMENT_HISTORY]: 'app_subscription_payment_history',
  [ZevaPageSelector.ADMIN_UPDATE_SUBSCRIPTION]: 'app_subscription_manage_subscription',
  [ZevaPageSelector.FAQ]: '',
  [ZevaPageSelector.GET_STARTED]: '',
};
export const ZEVA_PAGE_EXECUTE_PERMISSION: { [key in ZevaPageSelector]: string } = {
  [ZevaPageSelector.INSIGHTS]: 'app_insights',
  [ZevaPageSelector.ANALYTICS_VEHICLE]: 'app_analytics',
  [ZevaPageSelector.MONITORING_LIVE]: 'app_live',
  [ZevaPageSelector.MONITORING_REPLAY]: 'app_replay',
  [ZevaPageSelector.MONITORING_CONTROL]: 'app_control',
  [ZevaPageSelector.MONITORING_EN]: 'app_energy',
  [ZevaPageSelector.MONITORING_GEOFENCES]: 'app_geofences',
  [ZevaPageSelector.ADMIN_PROFILE]: '',
  [ZevaPageSelector.ADMIN_FLEET]: 'app_fleet',
  [ZevaPageSelector.ADMIN_ACCOUNT]: 'app_account',
  [ZevaPageSelector.ADMIN_SUBSCRIPTION]: 'app_subscription',
  [ZevaPageSelector.ADMIN_MANAGE_SUBSCRIPTION]: 'app_subscription_manage_subscription',
  [ZevaPageSelector.ADMIN_MANAGE_VEHICLES]: 'app_vehicles',
  [ZevaPageSelector.ADMIN_PAYMENT_DETAILS]: 'app_subscription_payment_details',
  [ZevaPageSelector.ADMIN_PAYMENT_HISTORY]: 'app_subscription_payment_history',
  [ZevaPageSelector.ADMIN_UPDATE_SUBSCRIPTION]: 'app_subscription_manage_subscription',
  [ZevaPageSelector.FAQ]: '',
  [ZevaPageSelector.GET_STARTED]: '',
};

/* Values */
export const SUPERCHARGER_IMAGE = 'assets/vehicles/superchargers/supercharger.png';

export const mapVehicleToImage = (model: string, user_color: string) => {
  const TESLA_MODEL_TO_IMAGE_MAPPING: { [key: string]: string } = {
    // Model 3
    'Model 3': 'Model3.png',
    model3: 'Model3.png',
    // Model S and variants
    'Model S': 'ModelS.png',
    'Model S2': 'ModelS.png',
    models: 'ModelS.png',
    models2: 'ModelS.png',
    lychee: 'ModelS.png',
    // Model X and variants
    'Model X': 'ModelX.png',
    modelx: 'ModelX.png',
    tamarind: 'ModelX.png',
    // Model Y
    'Model Y': 'ModelY.png',
    modely: 'ModelY.png',
    // Cybertruck
    'Cybertruck': 'Cybertruck.png',
    cybertruck: 'Cybertruck.png',
    // Placeholder
    placeholder: 'Tesla.png',
  };

  const image = TESLA_MODEL_TO_IMAGE_MAPPING[model];

  // Path only assumes using Tesla for now - will need update for future values
  return `/assets/vehicles/tesla-${user_color}/${image}`;
};

export const TESLA_MODEL_TOP_SPEED_MPH: { [key: string]: number } = {
  // Model 3
  'Model 3': 162,
  model3: 162,
  // Model S and variants
  'Model S': 149,
  'Model S2': 200,
  models: 149,
  models2: 200,
  lychee: 149,
  // Model X and variants
  'Model X': 155,
  'Model X Plaid': 163,
  modelx: 155,
  tamarind: 155,
  // Model Y
  'Model Y': 155,
  'Model Y Long Range': 135,
  modely: 155,
  // Cybertruck
  'Cybertruck': 130,
  cybertruck: 130,
  // all in mph
};

export const TESLA_MODEL_RANGE: { [key: string]: number } = {
  // Model 3
  'Model 3': 333,
  model3: 333,
  // Model S and variants
  'Model S': 405,
  'Model S2': 396,
  models: 405,
  models2: 396,
  lychee: 405,
  // Model X and variants
  'Model X': 348,
  'Model X Plaid': 333,
  modelx: 348,
  tamarind: 348,
  // Model Y
  'Model Y': 303,
  'Model Y Long Range': 330,
  modely: 303,
  // Cybertruck
  'Cybertruck': 320,
  cybertruck: 320,
  // all in miles
};

export const TESLA_MODEL_BATTERY_CAPACITY: { [key: string]: number } = {
  // Model 3
  'Model 3': 75,
  model3: 75,
  // Model S and variants
  'Model S': 100,
  'Model S2': 95,
  models: 100,
  models2: 95,
  lychee: 100,
  // Model X and variants
  'Model X': 100,
  'Model X Plaid': 95,
  modelx: 100,
  tamarind: 100,
  // Model Y
  'Model Y': 75,
  'Model Y Long Range': 75,
  modely: 75,
  // Cybertruck
  'Cybertruck': 123,
  cybertruck: 123,
  // all in kwh
};

export const routerTransition = trigger('routerTransition', [
  transition('* <=> *', [
    /* order */
    /* 1 */ query(':enter, :leave', style({ position: 'absolute', width: '100%', opacity: 0 }), {
      optional: true,
    }),
    query(
      ':enter',
      [
        style({ transform: 'translateY(66px)' }),
        animate(
          '0.5s cubic-bezier(0.4, 0.0, 0.2, 1)',
          style({ transform: 'translateY(0)', opacity: 1 })
        ),
      ],
      { optional: true }
    ),
    // /* 2 */ group([  // block executes in parallel
    //   query(':enter', [
    //     style({ transform: 'translateX(100%)' }),
    //     animate('0.5s ease-in-out', style({ transform: 'translateX(0%)' }))
    //   ], { optional: true }),
    //   query(':leave', [
    //     style({ transform: 'translateX(0%)' }),
    //     animate('0.5s ease-in-out', style({ transform: 'translateX(-100%)' }))
    //   ], { optional: true }),
    // ])
  ]),
]);

export const TRIP_LINE_COLOR_SET = [
  '#008cd8',
  '#00a0ec',
  '#00aefb',
  '#28bafc',
  '#4fc7fc',
  '#82d7fe',
  '#b4e7fe',
  '#e1f6ff',
];
export const TRIP_LINE_COLORS = {
  active: TRIP_LINE_COLOR_SET[2],
  inactive: TRIP_LINE_COLOR_SET[5] + '80',
  alert: '#A71622',
};

// ------------ ECharts Config ------------

function gradient(start: string, end: string): graphic.LinearGradient {
  return new graphic.LinearGradient(0, 0, 0, 1, [
    {
      offset: 0,
      color: start,
    },
    {
      offset: 1,
      color: end,
    },
  ]);
}

export const CHART_COLORS = [
  '#041958',
  '#1549B6',
  '#FF643E',
  '#FAAE43',
  // TODO: Revisit this with Nina, because current sequence is broken beyond 4 colours
  '#55ACD9',
  '#A71622',
  '#FF653E',
  '#F08468',
  '#FAAE43',
  '#82C3C5',
  '#F3AB83',
  '#F8D4A2',
];
export const CHART_COLORS_DARK: EChartsOption['color'] = [
  '#0052FF',
  '#2094FA',
  '#FF653E',
  '#FFe620',
];
export const CHART_COLORS_ALT = ['#3788DA', '#FF643E'];
export const CHART_COLORS_ALT_DARK = ['#2094FA', '#FF653E'];
export const CHART_COLORS_BLUE_HUE = [
  gradient('#041958', '#008ED8'),
  gradient('#041958', '#008ED8'),
  gradient('#041958', '#008ED8'),
  gradient('#041958', '#008ED8'),
];
export const CHART_COLORS_RED_HUE = [
  gradient('#A71622', '#FAAE43'),
  gradient('#A71622', '#FAAE43'),
  gradient('#A71622', '#FAAE43'),
  gradient('#A71622', '#FAAE43'),
];
export const CHART_COLORS_4_INVERTED = CHART_COLORS.slice(0, 4).reverse();
export const CHART_COLOR_INVERSE = [...CHART_COLORS].reverse();

export const CHART_TEXT_STYLE = {
  color: '#707070',
  fontFamily:
    // eslint-disable-next-line max-len
    // 'system-ui, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
    '"Jost", "Helvetica Neue", sans-serif',
  fontSize: 11.2,
  fontWeight: 'normal',
};

export const ZEVA_DEFAULT_CHART_OPTIONS: EChartsOption = {
  color: CHART_COLORS,
  textStyle: {
    fontFamily:
      // eslint-disable-next-line max-len
      // 'system-ui, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
      '"Jost", "Helvetica Neue", sans-serif',
    fontSize: 11.2,
    fontWeight: 'normal',
  },
  length: { type: 'scroll' },
  legend: { itemWidth: 25 * 0.5 },
};

export const DARK_MODIFIER: EChartsOption = {
  legend: {
    textStyle: {
      color: '#ffffff',
    },
  },
  textStyle: {
    color: '#ffffff',
  },
  tooltip: {
    textStyle: {
      color: '#ffffff',
    },
    backgroundColor: '#181818',
  },
};

export const DARK_CHART_COLOR: EChartsOption['textStyle'] = {
  color: '#ffffff',
};

export const DARK_CHART_LEGEND: EChartsOption['legend'] = {
  textStyle: {
    color: '#ffffff',
  },
};

export const DARK_TOOLTIP: EChartsOption['tooltip'] = {
  textStyle: {
    color: '#ffffff',
  },
  backgroundColor: '#181818',
};

export const BATTERY_LIFE_CHART_OPTIONS: EChartsOption = {
  ...ZEVA_DEFAULT_CHART_OPTIONS,
  tooltip: {
    trigger: 'axis',
    valueFormatter(value) {
      return parseFloat(value!.toString()).toFixed(2);
    },
    axisPointer: {
      label: {
        formatter: function (params) {
          return 'Cycle '.concat(params.value.toString());
        },
      },
    },
  },
  legend: {
    show: true,
    bottom: '1rem',
    itemWidth: 25 * 0.5,
    icon: 'circle',
  },
  xAxis: {
    type: 'category',
    name: 'Cycle Count',
    data: [],
  },
  yAxis: {
    name: 'Metric',
    type: 'value',
    splitLine: {
      show: false,
    },
    axisLine: {
      show: true,
    },
    axisTick: {
      show: true,
    },
  },
  series: [
    {
      data: [],
      type: 'line',
      showSymbol: false,
    },
  ],
};

export const COUNTRY_CODES = [
  'CA', // Canada
  'US', // United States
  'AU', // Australia
  'NL', // Netherlands
  'NO', // Norway
  'FI', // Finland
  'SE', // Sweden
  'DK', // Denmark
  'DE', // Germany
  'IE', // Ireland
  'GB', // United Kingdom
  'BE', // Belgium
  'ES', // Spain
  'FR', // France
  'GR', // Greece
];

export const COUNTRY_NAMES = {
  CA: 'Canada',
  US: 'United States',
  AU: 'Australia',
  NL: 'Netherlands',
  NO: 'Norway',
  FI: 'Finland',
  SE: 'Sweden',
  DK: 'Denmark',
  DE: 'Germany',
  IE: 'Ireland',
  GB: 'United Kingdom',
  BE: 'Belgium',
  ES: 'Spain',
  FR: 'France',
  GR: 'Greece',
};
