export const environment = {
  production: true,
  frontEndUrl: 'https://test.zevaglobal.com',
  apiUrl: 'https://api-test.zevaglobal.com/',
  adminUrl: 'http://localhost:4201/',
  debug: true,
  name: 'test',

  // Live Microservice
  liveMicroserviceUrl: 'wss://s6rehxird5.execute-api.ca-central-1.amazonaws.com/test/',

  // Square
  squareApplicationId: 'sandbox-sq0idb-yrZQHzXykJa3AvQujDs_Sw',
  squareLocationId: 'LK190QNJVZ009',

  // Stripe
  stripeApplicationId:
    // eslint-disable-next-line max-len
    'pk_test_51Ovfxq09v7yNXw2TbRxdQfVhVDtbLhpqE5nxt6Azy3qzSdCnMJMFiD4VwtfiLJArxXyYtCsjLyemC2bitjnMdA8200LhtJnMwc',
};
