import { MAP_THEME_DARK, MAP_THEME_SILVER } from './map.styles';

/**
 * Explanation:
 * The reason for using hardcoded constants is that using the google constants
 * will not allow our automated testing scripts to run.
 */
const leftCenterOfMap = 4;
const leftTopOfMap = 5;
const topLeftOfMap = 1;
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const leftBottomOfMap = 6;

/* Default google map options */
export const DefaultGoogleMapsOptions: google.maps.MapOptions = {
  // zoom: 10,
  // minZoom: 1,
  scaleControl: true,
  gestureHandling: 'cooperative',
  streetViewControl: true,
  streetViewControlOptions: {
    position: google.maps.ControlPosition.LEFT_BOTTOM,
  },
  fullscreenControl: true,
  fullscreenControlOptions: {
    position: google.maps.ControlPosition.LEFT_BOTTOM,
  },
  mapTypeControl: true,
  mapTypeControlOptions: {
    position: google.maps.ControlPosition.TOP_RIGHT,
  },
  zoomControl: true,
  zoomControlOptions: {
    position: google.maps.ControlPosition.LEFT_BOTTOM,
  },
  styles: MAP_THEME_SILVER,
};

export const DefaultGoogleMapsOptionsDark: google.maps.MapOptions = {
  ...DefaultGoogleMapsOptions,
  styles: MAP_THEME_DARK,
};

export const DefaultGoogleMapsOptionsLight: google.maps.MapOptions = {
  ...DefaultGoogleMapsOptions,
  styles: MAP_THEME_SILVER,
};

/* Country coordinates */
export const gCountryCoordinates: { [country: string]: google.maps.LatLngLiteral } = {
  Canada: <google.maps.LatLngLiteral>{
    lat: 58.883333,
    lng: -105.166667,
  },
  UnitedStates: <google.maps.LatLngLiteral>{
    lat: 37.09024,
    lng: -95.712891,
  },
  UnitedKingdom: <google.maps.LatLngLiteral>{
    lat: 55.378051,
    lng: -3.435973,
  },
  Australia: <google.maps.LatLngLiteral>{
    lat: -25.274398,
    lng: 133.775136,
  },
  Japan: <google.maps.LatLngLiteral>{
    lat: 36.204824,
    lng: 138.252924,
  },
  China: <google.maps.LatLngLiteral>{
    lat: 35.86166,
    lng: 104.195397,
  },
  PointNemo: <google.maps.LatLngLiteral>{
    lat: -48.876667,
    lng: -123.393333,
  },
  Netherlands: <google.maps.LatLngLiteral>{
    lat: 52.379189,
    lng: 4.899431,
  },
  Norway: <google.maps.LatLngLiteral>{
    lat: 60.472,
    lng: 8.468944,
  },
  Finland: <google.maps.LatLngLiteral>{
    lat: 61.92411,
    lng: 25.748151,
  },
  Sweden: <google.maps.LatLngLiteral>{
    lat: 60.128161,
    lng: 18.643501,
  },
  Denmark: <google.maps.LatLngLiteral>{
    lat: 56.26392,
    lng: 9.501785,
  },
  Germany: <google.maps.LatLngLiteral>{
    lat: 51.165691,
    lng: 10.451526,
  },
  Ireland: <google.maps.LatLngLiteral>{
    lat: 53.41291,
    lng: -8.24389,
  },
  Belgium: <google.maps.LatLngLiteral>{
    lat: 50.850346,
    lng: 4.351711,
  },
  Spain: <google.maps.LatLngLiteral>{
    lat: 40.463667,
    lng: -3.74922,
  },
  France: <google.maps.LatLngLiteral>{
    lat: 46.603354,
    lng: 1.888334,
  },
  Greece: <google.maps.LatLngLiteral>{
    lat: 39.074208,
    lng: 21.824312,
  }
};

/* Region types */
export enum RegionTagTypes {
  Country = 'country',
  Province = 'province',
  City = 'city',
  FSA = 'fsa',
  PostalCode = 'postalcode',
}

/* Google Map zoom levels
   some are taken from: https://developers.google.com/maps/documentation/javascript/overview */
export enum ZoomLevels {
  Default = 12,
  World = 1,
  Landmass = 5,
  Continent = 5,
  Country = 4,
  Province = 6,
  FSA = 13,
  City = 12,
  Quebec = 5,
  Vancouver = 11,
  Toronto = 10,
  L8B = 11,
  X0E = 5,
  Streets = 15,
  PostalCode = 18,
  Store = 16,
  Buildings = 20,
}

/* Useful map zoom levels */
export const dZoomLevels = {
  [RegionTagTypes.Country]: ZoomLevels.Country,
  [RegionTagTypes.Province]: ZoomLevels.Province,
  [RegionTagTypes.City]: ZoomLevels.City,
  [RegionTagTypes.FSA]: ZoomLevels.FSA,
  [RegionTagTypes.PostalCode]: ZoomLevels.PostalCode,
};

/* Respective scale of some map zoom levels */
export const MapZoomScale: { [zoom: number]: number } = {
  14: 250,
  13: 500,
  12: 1000,
  11: 2000,
  10: 5000,
  9: 10000,
  8: 20000,
  7: 50000,
  6: 100000,
  5: 200000,
  4: 500000,
};

/* Undo image for map figure changes */
export const FigureUndoImage =
  'https://cdn4.iconfinder.com/data/icons/ionicons/512/icon-ios7-undo-512.png';

export const CLUSTER_IMAGE_PATH =
  'https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m';
