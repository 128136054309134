import { trigger } from '@angular/animations';
import { CdkOverlayOrigin, Overlay } from '@angular/cdk/overlay';
import {
  AfterViewInit,
  Component,
  Directive,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnInit,
  Optional,
  Output,
  Self,
  ViewChild,
} from '@angular/core';
import { ALL_TRANSITION_ANIMATIONS } from './overlay.transitions';

@Component({
  selector: 'shared-popover',
  templateUrl: './popover.component.html',
  styleUrls: ['./popover.component.scss'],
  animations: [trigger('fade', ALL_TRANSITION_ANIMATIONS)],
})
export class PopoverComponent implements AfterViewInit {
  @ViewChild('overlay', { read: Overlay })
  overlay!: Overlay;

  @Input()
  anchor!: CdkOverlayOrigin;

  @Input('class')
  panelClass: string | string[] = [];

  /**
   * Applies a css class to the backdrop element spanning the page behind the oevrlay
   *
   * Use `class="cdk-overlay-dark-backdrop"` to apply default dark translucent backdrop styling
   */
  @Input()
  backdropClass: string | string[] = [];

  @Input('open')
  isOpen = false;
  @Output()
  openChange = new EventEmitter<boolean>();

  /**
   * Positioning of the popover on the page.
   *
   * `relative` - popover will be positioned in a space relative to its anchor \
   * `absolute` - popover will be positioned relative to the body element
   *              regardless of its original anchor position
   */
  @Input()
  position: 'relative' | 'absolute' = 'relative';

  ngAfterViewInit(): void {
    if (!this.anchor) {
      throw new Error(
        '[Popover] Popover Component requires a valid CdkOverlayOrigin [anchor] input'
      );
    }
  }

  toggle(): void {
    this.isOpen = !this.isOpen;
    this.openChange.emit(this.isOpen);
  }

  open(): void {
    this.isOpen = true;
    this.openChange.emit(this.isOpen);
  }

  close(): void {
    this.isOpen = false;
    this.openChange.emit(this.isOpen);
  }

  onBackdropClick(): void {
    this.close();
  }

  ngOnDestroy(): void {
    this.openChange.complete();
  }
}

@Directive({
  selector: '[popoverTriggerFor], [popoverTrigger]',
  exportAs: 'popoverTrigger',
})
export class PopoverTriggerDirective implements OnInit, CdkOverlayOrigin {
  @Input()
  popoverTriggerFor?: PopoverComponent;

  constructor(
    @Self()
    @Optional()
    public readonly overlay: CdkOverlayOrigin | null,
    public readonly elementRef: ElementRef
  ) {}

  @HostListener('click')
  private _onTriggerClick(): void {
    this.popoverTriggerFor?.open();
  }

  toggle(open?: boolean): void {
    if (open) this.popoverTriggerFor?.open();
    else this.popoverTriggerFor?.close();
  }

  open(): void {
    this.toggle(true);
  }

  close(): void {
    this.toggle(false);
  }

  ngOnInit(): void {
    if (this.popoverTriggerFor && !this.popoverTriggerFor.anchor && this.overlay)
      this.popoverTriggerFor.anchor = this.overlay;
  }
}
